.App 
{
  background-color: white;
  font-size: 16px;
  color: #505050;
  height: 100vh;
  width: 100vw;
  display: flex;
}

h1
{
  font-size: 24px;
}

h2
{
  font-size: 22px;
}

h3
{
  font-size: 18px;
}

small
{
  font-size: 14px;
}
