.previewTitle {
    font-weight: bold;
}

fieldset {
    border-radius: 5px;
    padding: 0px 20px;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
}

.ignis-legend {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.time-box {
    border: 1px solid lightgray;
    padding: 5px;
}

/* 2021-09-17: Copied from foodsafety ignis-component.css */

table.component-table {
    width: 100%;
    margin: 10px 10px 10px 0px;
}

table.component-table td {
    width: 50%;
    padding: 0px 10px;
}

table.component-table .form-check-input {
    /* target: check component checkbox */
    margin: 11px 0px 0px 15px;
}

table.component-table .form-check-label {
    /* target: check component label */
    margin: 8px 0px 0px 35px;
}

.ag-details-row {
    /* ag-grid master-detail, padding around child grid */
    padding: 15px 15px 15px 30px !important;
  }

.ag-theme-alpine {
    /* https://www.ag-grid.com/react-data-grid/global-style-customisation-selections/ */
    --ag-range-selection-border-color: rgb(193, 0, 97);
    --ag-range-selection-border-style: dashed;
    --ag-range-selection-background-color: rgb(255, 0, 128, 0.1);
    --ag-range-selection-background-color-2: rgb(255, 0, 128, 0.19);
    --ag-range-selection-background-color-3: rgb(255, 0, 128, 0.27);
    --ag-range-selection-background-color-4: rgb(255, 0, 128, 0.34);
    --ag-range-selection-highlight-color: rgb(60, 188, 0, 0.3);
    --ag-header-background-color: #EAF2FF;
    --ag-border-color: #EAF2FF;
}

div.ignis-bi-tabular-report {
    width: 65%;
}

div.ignis-bi-chart-report {
    width: 35%;
}

.ignis-bi-pinned-row {
    font-weight: bold;
    background-color: #f4f0ec;
}

.failed-entity-logs {
    /* color: red; not working ? */
    background-color: #FFCCCB;
}

.ignis-warning-cell {
    color: red !important;
}

/* StorefrontOpeningHours */

input[type=text].ignis-opening-hour-input.ignis-opening-hour-invalid {
    color: #DC143C !important;
    /*  #DC143C  */
}

input[type=text].ignis-opening-hour-input:focus {
    outline: none;
    color: black !important;
    border-bottom: solid 1px blue !important;
}

input[type=text].ignis-opening-hour-input.ignis-opening-hour-invalid:focus {
    color: red !important;
}

.Select-input > #account-switch-search-bar {
    height: 100%;
    padding: 0px;
}