/* Only impacts row cell */
.ag-row .ag-cell 
{
    font-size: 15px;
    color:#707070 ;

    display: flex;
    align-items: center;
}

.ag-header-cell-text 
{ 
    color:#606060;
    font-size: 14px; 
    font-weight: bold;
}

.ag-header-cell-label 
{ 
    justify-content: center;
}

.ag-theme-alpine .ag-row-selected 
{ 
    background-color: #E8E8E8; 
}

.ag-row.cancelled .ag-cell
{
    color: mediumslateblue;
    font-weight: bold;
}

.ag-row.refunded .ag-cell
{
    color: indianred;
    font-weight: bold;
}

.ag-row.task-failed .ag-cell
{
    color: indianred;
    font-weight: bold;
}

.ag-row.task-time-exceeded .ag-cell
{
    color: orange;
    font-weight: bold;
}